<template>
  <div>
    <!-- <pre>{{ parentdetails }}</pre> -->
    <v-row class="px-4 product-row">
      <v-col md="12" class="px-0 py-0">
        <v-expansion-panels elevation="1" v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header
              style="background-color: rgb(245, 241, 241); min-height: 1px !important"
            >
              <h5 class="my-0 px-0 py-0">Reservation Details</h5>
            </v-expansion-panel-header>
            <v-divider class="my-0"></v-divider>
            <v-expansion-panel-content class="px-0 py-0">
              <v-row class="">
                <v-col md="3">
                  <h6 class="blue--text text--darken-4 text-h6">Customer Detail</h6>
                  <div>
                    <v-icon color="disabled" size="18" class="me-1">mdi-account</v-icon>
                    <span style="text-transform: uppercase"
                      ><b>{{ parentdetails.customer_name }}</b></span
                    >
                  </div>
                  <div>
                    <v-icon color="disabled" size="18" class="me-1">mdi-email</v-icon>

                    <span style="text-transform: uppercase">{{
                      parentdetails.customer_email
                    }}</span>
                  </div>
                  <div>
                    <v-icon color="disabled" size="18" class="me-1">mdi-phone</v-icon>
                    <span style="text-transform: uppercase">{{
                      parentdetails.customer_number
                    }}</span>
                  </div>
                </v-col>
                <!-- <v-col md="3">
                  <h6 class="blue--text text--darken-4 text-h6">Restaurant Detail</h6>
                  <div>
                    <v-icon color="disabled" size="18" class="me-1">mdi-account</v-icon>
                    {{parentdetails.restaurant.name}}
                  </div>
                  <div>
                    <v-icon color="disabled" size="18" class="me-1">mdi-map</v-icon>
                    {{parentdetails.restaurant.address}}
                  </div>
                
                </v-col> -->
                <v-col md="3">
                  <h6 class="blue--text text--darken-4 text-h6">Date & Timing</h6>
                  <div>
                    <div class="d-flex">
                      <div>
                        <v-icon color="disabled" size="18" class="me-1">mdi-clock</v-icon>
                        <b
                          >{{ formatedateTimes(parentdetails.booking_date) }}
                          {{ parentdetails.booking_time }}</b
                        >
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col md="3"> </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col md="12" class="px-0 py-0 pt-5">
        <v-expansion-panels elevation="1" v-model="panels" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header style="background-color: rgb(245, 241, 241)">
              <h5 class="my-0 px-0 py-0">Reason Status Change</h5>
            </v-expansion-panel-header>
            <v-divider class="my-0"></v-divider>
            <v-expansion-panel-content class="px-0 py-0">
              <div class="w-100 po-line-items">
                <table width="100%">
                  <thead>
                    <tr >
                      <th class="pa-2" width="25%">
                        <h6 class="blue--text text--darken-4 text-h6">Reason</h6>
                      </th>
                      <th class="pa-2" width="25%">
                        <h6 class="blue--text text--darken-4 text-h6">Created Date</h6>
                      </th>
                      <th class="pa-2" width="25%">
                        <h6 class="blue--text text--darken-4 text-h6">Status</h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in parentdetails.reason" :key="index.id">
                      <td class="px-2">
                        <div v-if="row.reason" class="text-truncate" style="width: 250px">
                    {{ row.reason }}
                  </div>
                  <span
                    v-if="row && row.reason && row.reason.length > 20"
                    style="color: #24326d; cursor: pointer; font-size: 12px"
                    @click="
                      remarkPop(row.reason)
                    "
                    >Read More</span
                  >
                  <div v-if="!row.reason" class="text-truncate" style="width: 250px">
                    No reason
                  </div>
                      </td>
                      <td class="px-2">
                        <div>
                          <span>{{ formatedateTimes(row.added_at) }}</span>
                        </div>
                      </td>
                      <td class="px-2">
                        <div>
                          <v-chip
                            v-if="row.status == 2"
                            class="white--text mr-2 mt-2"
                            small
                            label
                            :color="row.status == 2 ? 'green' : 'green'"
                            >Confirm</v-chip
                          >
                          <v-chip
                            v-if="row.status == 1"
                            class="white--text mr-2  mt-2"
                            small
                            label
                            :color="row.status == 1 ? 'purple' : 'purple'"
                            >New</v-chip
                          >
                          <v-chip
                            v-if="row.status == 3"
                            class="white--text mr-2   mt-2"
                            small
                            label
                            :color="row.status == 3 ? 'cyan' : 'cyan'"
                            >Pending</v-chip
                          >
                          <v-chip
                            v-if="row.status == 4"
                            class="white--text mr-2  mt-2"
                            small
                            label
                            :color="row.status == 4 ? 'orange' : 'orange'"
                            >Cancelled</v-chip
                          >
                          <v-chip
                            v-if="row.status == 5"
                            class="white--text mr-2  mt-2"
                            small
                            label
                            :color="row.status == 5 ? 'blue' : 'blue'"
                            >Completed</v-chip
                          >
                          <v-chip
                            v-if="row.status == 6"
                            class="white--text mr-2  mt-2"
                            small
                            label
                            :color="row.status == 6 ? 'black' : 'black'"
                            >No Show</v-chip
                          >
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <Dialog :dialog="dialog" :dialogWidth="900">
      <template v-slot:title>Reason</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <img
                
                width="80"
                height="80"
                src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/files/image/png/OFVhotIVFLGfIcidqwYD9YQxR3edOtSq7cZaTji6.png"
              />
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p m-0"><b class="red--text text--lighten-1"></b>{{ text }}</p>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn depressed tile @click="dialog = false"> Close </v-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Dialog from "@/view/components/Dialog";

export default {
  name: "purchase-order-detail",
  props: {
    parentdetails: {
      type: Object,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      panel: [0],
      panels: [0],
      reason:null,
      dialog:false,
      text:false,
      customer_number:null,
      customer_name:null,
      // product: {
      // }
      purchaseOrder: [{}],
    };
  },
  components: {
    
    Dialog
  },
  methods: {
    remarkPop(reason) {
      this.text = reason;
      this.dialog = true;
    },
    formatedateTimes(param) {
      return moment(param).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.th {
  font-size: 14px !important;
}

.product-row {
  margin: 0 !important;
}
</style>
